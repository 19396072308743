import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { take, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Plugins } from '@capacitor/core';
import { Observable, Subscription } from 'rxjs';
import { UserCredentials, User as UserModel } from '../models';
import { StorageService } from './storage.service';
import { AngularFireFunctions } from '@angular/fire/functions';

const { SplashScreen } = Plugins;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: firebase.User = null;
  userDetails: UserModel = {} as UserModel;
  fcmTokens = {};
  nickname = '';
  admin = false;
  vet = false;
  tosAccepted = false;
  veterinarian = '';
  phone = '';

  userSubscription: Subscription;

  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private router: Router,
    private storageService: StorageService,
    private fns: AngularFireFunctions
  ) {
    this.afAuth.authState.subscribe((authStateResult) => {
      this.user = authStateResult;
      this.storageService.setUser(authStateResult);
      if (this.user) {
        this.userSubscription = this.db
          .doc(`users/${this.currentUserId}`)
          .valueChanges()
          .pipe(
            tap((res: UserModel) => {
              this.userDetails = res;
              this.storageService.setUserDetails(res);
              this.nickname = res['nickname'];
              this.fcmTokens = res['fcmTokens'] instanceof Object ? res['fcmTokens'] : {};
              this.storageService.setPracticeId(res['practiceId']);
              this.admin = res['admin'];
              this.vet = res['vet'];
              this.veterinarian = res['veterinarian'];
              this.phone = res['phone'];
              this.tosAccepted = res['tosAccepted'];
              this.storageService.isAdmin = this.isAdmin;
              this.storageService.isVet = this.isVet;
              SplashScreen.hide().catch((e) => {
                console.error('SplashScreen error', e);
              });
              if (this.router.url === '/login') {
                this.router.navigate([this.tosAccepted ? '/home' : '/privacy']);
              }
            })
          )
          .subscribe();
      } else {
        SplashScreen.hide().catch((e) => {
          console.error('SplashScreen error', e);
        });
        if (this.userSubscription) {
          this.userSubscription.unsubscribe();
        }
        this.router.navigate(['/login'], { replaceUrl: true });
      }
    });
  }

  getState() {
    return this.afAuth.authState;
  }

  signUp(credentials: UserCredentials) {
    if (this.isAdmin) {
      const body = {
        users: [
          {
            password: credentials.password,
            nickname: credentials.nickname,
            email: credentials.email,
            admin: credentials.admin,
            vet: credentials.vet,
            veterinarian: credentials.veterinarian,
            phone: credentials.phone,
            street: credentials.street,
            housenumber: credentials.housenumber,
            zipcode: credentials.zipcode,
            city: credentials.city,
            country: credentials.country,
            ubn: credentials.ubn,
            location: credentials.location,
            practiceId: this.storageService.practiceId,
            created: firebase.firestore.FieldValue.serverTimestamp(),


      
          },
        ],
      };
      const callable = this.fns.httpsCallable('createUser');
      return callable(body).toPromise();
    } else {
      return this.afAuth.createUserWithEmailAndPassword(credentials.email, credentials.password).then((data) => {
        return this.db.doc(`users/${data.user.uid}`).set({
          nickname: credentials.nickname,
          email: data.user.email,
          admin: credentials.admin,
          vet: credentials.vet,
          veterinarian: credentials.veterinarian,
          phone: credentials.phone,
          practiceId: this.storageService.practiceId,
          created: firebase.firestore.FieldValue.serverTimestamp(),
        });
      });
    }
  }

  /**
   * Add FCM token to user profile & practice
   * XX SP: Only if there is an update by checking on value change by the fast JSON.stringify function
   * @param fcmToken
   */
  addFCMToken(fcmToken) {
    let fcmTokensBefore = JSON.stringify(this.fcmTokens);
    if (!this.fcmTokens[fcmToken]) {
      let midnight = new Date();
      midnight.setHours(0, 0, 0, 0);
      this.fcmTokens[fcmToken] = { last_app_open: midnight };
    }
    if (fcmTokensBefore !== JSON.stringify(this.fcmTokens)) {
      return this.updateUser({ fcmTokens: this.fcmTokens });
    }
    let tokens = this.db.collection('practicetokens').doc(this.storageService.practiceId);
    tokens.set(
      {
        tokens: firebase.firestore.FieldValue.arrayUnion(fcmToken),
      },
      { merge: true }
    );
  }

  updateUser(data) {
    return this.db.doc(`users/${this.user.uid}`).update(data);
  }

  isNicknameAvailable(name: string) {
    return this.db
      .collection('users', (ref) => ref.where('nickname', '==', name).limit(1))
      .valueChanges()
      .pipe(
        take(1),
        map((user) => {
          return user;
        })
      );
  }

  signIn(credentials: UserCredentials, saveCredentials = false) {
    this.afAuth.setPersistence(
      saveCredentials ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.NONE
    );
    return this.afAuth.signInWithEmailAndPassword(credentials.email, credentials.password);
  }

  signOut() {
    return this.afAuth.signOut();
  }

  resetPw(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  // Above this line alle methods have to do with a user logging in / signing up

  // Below this line are extra methods on an arbitrary user (user management by superuser)

  getUserdetails(userid: string): Observable<any> {
    // ${this.afAuth.auth.currentUser.uid}
    // @ts-ignore
    return this.db.doc(`users/${userid}`).valueChanges();
  }

  getUserNickname(userid: string) {
    let user = this.getUserdetails(`${userid}`).subscribe();
    return user['nickname'];
  }

  getUserVeterinarian(userid: string) {
    let user = this.getUserdetails(`${userid}`).subscribe();
    return user['veterinarian'];
  }

  updateUserProfile(
    userid: string,
    userDetails: UserModel
  ) {
    // ${this.currentUserId}
    return this.db.doc(`users/${userid}`).update({
      nickname: userDetails.nickname,
      phone: userDetails.phone,
      street: userDetails.street ? userDetails.street : '',
      housenumber: userDetails.housenumber ? userDetails.housenumber : '',
      zipcode: userDetails.zipcode ? userDetails.zipcode : '',
      city: userDetails.city ? userDetails.city : '',
      country: userDetails.country ? userDetails.country : '',
      ubn: userDetails.ubn ? userDetails.ubn : '',
      veterinarian: userDetails.veterinarian ? userDetails.veterinarian : '',
      admin: userDetails.admin,
      vet: userDetails.vet,
      location: userDetails.location ? userDetails.location : '',
    });
  }

  getPractice() {
    return this.db.doc(`practices/${this.storageService.practiceId}`).valueChanges();
  }

  getVeterinarians() {
    console.log('Auth practiceId ', this.storageService.practiceId);
  }

  get authenticated(): boolean {
    return this.user !== null;
  }

  get currentUser() {
    return this.authenticated ? this.user : null;
  }

  get currentUserId(): string {
    return this.authenticated ? this.user.uid : '';
  }

  get isAdmin(): boolean {
    return this.user && this.admin === true;
  }

  get isVet(): boolean {
    return this.user && this.vet === true;
  }

  deleteUser(userid) {
    /*
    @TODO
    - really delete everything like chatgroups, tasks, ...
    - in case of vet remove from vet list
    - also the authenticating user
    */
    this.db.doc(`users/${userid}`).update({
      practiceId: null,
    });
  }
}
