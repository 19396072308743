import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const ROUTES: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then((m) => m.LogoutPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then((m) => m.RegisterPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  //  { path: 'appointments', loadChildren: './pages/appointments/appointments.module#AppointmentsPageModule', canActivate: [AuthGuard] },
  //  { path: 'appointment/:id', loadChildren: './pages/appointment/appointment.module#AppointmentPageModule', canActivate: [AuthGuard] },
  //  { path: 'appointment', loadChildren: './pages/appointment/appointment.module#AppointmentPageModule', canActivate: [AuthGuard] },
  {
    path: 'profile/:id',
    loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'map/:id',
    loadChildren: () => import('./pages/map/map.module').then((m) => m.MapPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'links',
    loadChildren: () => import('./pages/links/links.module').then((m) => m.LinksPageModule),
    canActivate: [AuthGuard],
  },
  //  { path: 'courses', loadChildren: './pages/courses/courses.module#CoursesPageModule', canActivate: [AuthGuard] },
  //  { path: 'coursedetails/:id', loadChildren: './pages/coursedetails/coursedetails.module#CoursedetailsPageModule', canActivate: [AuthGuard] },
  //  { path: 'coursedetails', loadChildren: './pages/coursedetails/coursedetails.module#CoursedetailsPageModule', canActivate: [AuthGuard] },
  //  { path: 'courseregister/:id', loadChildren: './pages/courseregister/courseregister.module#CourseregisterPageModule', canActivate: [AuthGuard] },
  //  { path: 'products', loadChildren: './pages/products/products.module#ProductsPageModule', canActivate: [AuthGuard] },
  //  { path: 'products-ordered', loadChildren: './pages/products-ordered/products-ordered.module#ProductsOrderedPageModule', canActivate: [AuthGuard] },
  {
    path: 'announcements',
    loadChildren: () => import('./pages/announcements/announcements.module').then((m) => m.AnnouncementsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'announcement/:id',
    loadChildren: () => import('./pages/announcement/announcement.module').then((m) => m.AnnouncementPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'announcement',
    loadChildren: () => import('./pages/announcement/announcement.module').then((m) => m.AnnouncementPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'tasks/:userid',
    loadChildren: () => import('./pages/tasks/tasks.module').then((m) => m.TasksPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'tasks',
    loadChildren: () => import('./pages/tasks/tasks.module').then((m) => m.TasksPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'task/:userid/:id',
    loadChildren: () => import('./pages/task/task.module').then((m) => m.TaskPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'task/:userid',
    loadChildren: () => import('./pages/task/task.module').then((m) => m.TaskPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'task',
    loadChildren: () => import('./pages/task/task.module').then((m) => m.TaskPageModule),
    canActivate: [AuthGuard],
  },
  //  { path: 'shoppingcart', loadChildren: './pages/shoppingcart/shoppingcart.module#ShoppingcartPageModule', canActivate: [AuthGuard] },
  {
    path: 'chats',
    loadChildren: () => import('./pages/chats/chats.module').then((m) => m.ChatsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'chat/:id',
    loadChildren: () => import('./pages/chat/chat.module').then((m) => m.ChatPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'chats/start/:userid/:title',
    loadChildren: () => import('./pages/start-chat/start-chat.module').then((m) => m.StartChatPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'start',
    loadChildren: () => import('./pages/start/start.module').then((m) => m.StartPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'farmers',
    loadChildren: () => import('./pages/farmers/farmers.module').then((m) => m.FarmersPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'farmer/:id',
    loadChildren: () => import('./pages/farmer/farmer.module').then((m) => m.FarmerPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'farmertasks',
    loadChildren: () => import('./pages/farmertasks/farmertasks.module').then((m) => m.FarmertasksPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'vets',
    loadChildren: () => import('./pages/vets/vets.module').then((m) => m.VetsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then((m) => m.PrivacyPageModule),
  },
  {
    path: 'info',
    loadChildren: () => import('./pages/info/info.module').then((m) => m.InfoPageModule),
  },
  {
    path: 'addusers',
    loadChildren: () => import('./pages/addusers/addusers.module').then((m) => m.AddusersPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'updateusers',
    loadChildren: () => import('./pages/updateusers/updateusers.module').then((m) => m.UpdateusersPageModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
