export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDtL_rGZqpqvRe3N4xTkB-f6jgbJ-mEz0c',
    authDomain: 'boviapp-5ee57.firebaseapp.com',
    databaseURL: 'https://boviapp-5ee57.firebaseio.com',
    projectId: 'boviapp-5ee57',
    storageBucket: 'boviapp-5ee57.appspot.com',
    messagingSenderId: '28792049315',
    appId: '1:28792049315:web:c68733fd83f2159aca7916',
    measurementId: 'G-FQ53TN28GZ',
  },
};
