<ion-app [class]="darkModeService.isEnabled ? 'dark' : ''">
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" menuId="clientMenu">
      <ion-header>
        <ion-toolbar>
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ng-container *ngFor="let p of appPages">
            <ion-menu-toggle
              auto-hide="false"
              *ngIf="
                !p.showFor ||
                (p.showFor.includes('vet') && auth.isVet) ||
                (p.showFor.includes('admin') && auth.isAdmin) ||
                (p.showFor.includes('other') && !auth.isAdmin && !auth.isVet)
              "
            >
              <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
                <ion-icon slot="start" [name]="p.icon"></ion-icon>
                <ion-label>
                  {{ p.title | translate }}
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ng-container>
        </ion-list>
      </ion-content>
    </ion-menu>
    <!--     <ion-menu contentId="main-content" menuId="adminMenu">
      <ion-header>
        <ion-toolbar>
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-menu-toggle auto-hide="false" *ngFor="let p of adminPages">
            <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
              <ion-icon slot="start" [name]="p.icon"></ion-icon>
              <ion-label>
                {{p.title}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
 -->
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
