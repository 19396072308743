import { Injectable } from '@angular/core';
import { Plugins, StatusBarStyle } from '@capacitor/core';
import { StorageService } from './storage.service';
import { Platform } from '@ionic/angular';
const { StatusBar } = Plugins;
@Injectable({
  providedIn: 'root',
})
export class DarkModeService {
  isEnabled = false;
  darkModeSetting = 'device';
  systemDarkModeMatch;
  currentStatusBarColor;

  constructor(private storage: StorageService, private plt: Platform) {
    try {
      this.systemDarkModeMatch = window.matchMedia('(prefers-color-scheme: dark)');
      this.systemDarkModeMatch.addEventListener('change', (e) => {
        this.updateDarkMode();
      });
    } catch (e) {
      console.log('System Dark Mode Match Error', e.message);
    }
  }

  setDarkMode() {
    this.storage.set('darkModeSetting', this.darkModeSetting);
    this.darkMode(this.darkModeSetting);
  }

  darkMode(val) {
    this.darkModeSetting = val;
    this.updateDarkMode();
    if (this.isEnabled) {
      this.setStatusBar(false);
    } else {
      this.setStatusBar(true);
    }
  }

  updateDarkMode() {
    if (this.darkModeSetting === 'device' && this.systemDarkModeMatch) {
      this.isEnabled = this.systemDarkModeMatch.matches;
    } else {
      this.isEnabled = this.darkModeSetting === 'alwayson';
    }
  }

  // light = black text, light = false = white text
  setStatusBar(blackText = true) {
    if (this.plt.is('android')) {
      if (this.currentStatusBarColor !== StatusBarStyle.Dark) {
        this.currentStatusBarColor = StatusBarStyle.Dark;
        StatusBar.setStyle({
          style: StatusBarStyle.Dark,
        }).catch((e) => {
          console.log('StatusBar Error', e);
        });
      }
      return;
    }
    if (this.isEnabled) {
      blackText = false;
    }
    const newStatus = blackText ? StatusBarStyle.Light : StatusBarStyle.Dark;
    if (this.currentStatusBarColor !== newStatus) {
      this.currentStatusBarColor = newStatus;
      StatusBar.setStyle({
        style: newStatus,
      }).catch((e) => {
        console.log('StatusBar Error', e);
      });
    }
  }
}
