import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { Plugins, StatusBarStyle } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './services/storage.service';
import { AuthService } from './services/auth.service';
import { DarkModeService } from './services/dark-mode.service';

const { StatusBar, Device, FirebaseCrashlytics } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    {
      title: 'HOME.PAGE_TITLE',
      url: '/home',
      icon: 'home-outline',
    },
    {
      title: 'HOME.CHATS',
      url: '/chats',
      icon: 'chatbubbles-outline',
    },
    /*    {
          title: 'Afspraken',
          url: '/appointments',
          icon: 'calendar-outline'
        }, */
    {
      title: 'TASK.TASK_PLURAL',
      url: '/tasks',
      icon: 'alarm-outline',
      showFor: ['other'],
    },
    {
      title: 'TASK.TASK_PLURAL',
      url: '/farmertasks',
      icon: 'alarm-outline',
      showFor: ['vet', 'admin'],
    },
    {
      title: 'ANNOUNCEMENTS.PAGE_TITLE',
      url: '/announcements',
      icon: 'calendar-outline',
    },
    /*    {
          title: 'Bestellen',
          url: '/products',
          icon: 'cart-outline'
        },
        {
          title: 'Cursussen',
          url: '/courses',
          icon: 'list-outline'
        }, */
    {
      title: 'LINKS.PAGE_TITLE',
      url: '/links',
      icon: 'earth-outline',
    },
    {
      title: 'PROFILE.PAGE_TITLE',
      url: '/profile',
      icon: 'person-outline',
    },
    {
      title: 'MENU.APP_SETTINGS',
      url: '/settings',
      icon: 'cog-outline',
    },
    {
      title: 'PRIVACY.PAGE_TITLE',
      url: '/privacy',
      icon: 'document-attach-outline',
    },
    {
      title: 'MENU.INFO',
      url: '/info',
      icon: 'information-circle-outline',
    },
    {
      title: 'MENU.LOG_OUT',
      url: '/logout',
      icon: 'log-out-outline',
    },
  ];

  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private storageService: StorageService,
    private auth: AuthService,
    public darkModeService: DarkModeService
  ) {
    this.darkModeService.darkMode(this.storageService.get('darkModeSetting', 'device'));
    this.initializeApp();
  }

  initializeApp() {
    let languages = ['nl', 'en'];
    this.translate.addLangs(languages);
    let storageLanguage = this.storageService.get('language', false);
    if (!storageLanguage || !languages.includes(storageLanguage)) {
      let browserLang = this.translate.getBrowserLang();
      if (browserLang && languages.includes(browserLang)) {
        this.translate.use(browserLang);
      } else {
        this.translate.use(this.translate.getDefaultLang());
      }
    } else if (languages.includes(storageLanguage)) {
      this.translate.use(storageLanguage);
    }

    this.platform.ready().then(() => {
      Device.getInfo().then((res) => {
        if (res.platform !== 'web') {
          StatusBar.setStyle({ style: StatusBarStyle.Dark }).catch((e) => {
            console.error('StatusBar error', e);
          });
        }
      });
    });
  }
}
