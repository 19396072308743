import { Injectable } from '@angular/core';
import { Announcement, Group, Practice, Task, User } from '../models';
import firebase from 'firebase';
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  // HOME PAGE
  homeUserTasks: Task[];
  homeFarmerTasks: Task[];
  homeGroups: Group[];

  allAnnouncements: Array<Announcement>;
  allFarmers: Array<User>;
  allVets: Array<User>;
  announcements: Array<Announcement>;
  practiceId = '';
  userDetails: User = {} as User;
  farmers: Array<User>;
  isAdmin = false;
  isVet = false;
  user: firebase.User = null;
  userTasks: Task[];
  farmerTasks: Task[];
  groups: Group[];
  practice: Practice = null;
  phone = '';
  nickname = '';
  userId = '';

  countries = ['NL', 'UK'];

  constructor() {
    this.allAnnouncements = this.get('allAnnouncements', []);
    this.allFarmers = this.get('allFarmers', []);
    this.allVets = this.get('allVets', []);
    this.practice = this.get('practice');
    this.practiceId = this.get('practiceId');
    this.user = this.get('user', null);
    this.homeGroups = this.get('homeGroups', []);
    this.homeFarmerTasks = this.get('homeFarmerTasks', []);
    this.homeUserTasks = this.get('homeUserTasks', []);
    this.userId = this.user ? this.user.uid : null;
  }

  // SET
  set(key, value) {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  // READ
  get(key, defaultValue = null) {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return defaultValue;
  }

  // DELETE
  delete(key) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }

  setPractice(practice) {
    if (practice) {
      this.practice = practice;
      this.phone = practice?.phone;
      this.set('practice', practice);
    }
  }

  setPracticeId(practiceId) {
    this.practiceId = practiceId;
    this.set('practiceId', practiceId);
  }

  setAnnouncements(announcements) {
    this.allAnnouncements = announcements;
    this.announcements = announcements;
    this.set('allAnnouncements', announcements);
    this.set('announcements', announcements);
  }

  setUserTasks(userTasks) {
    this.userTasks = userTasks;
    this.set('userTasks', userTasks);
  }

  setVets(allVets) {
    this.allVets = allVets;
    this.set('allVets', allVets);
  }

  setFarmers(allFarmers) {
    this.allFarmers = allFarmers;
    this.farmers = allFarmers;
    this.set('allFarmers', allFarmers);
  }

  setFarmerTasks(farmerTasks) {
    this.farmerTasks = farmerTasks;
    this.set('farmerTasks', farmerTasks);
  }

  setGroups(groups) {
    this.groups = groups;
    this.set('groups', groups);
  }

  setUserDetails(userDetails) {
    this.set('userDetails', userDetails);
    this.userDetails = userDetails;
  }

  setUser(user) {
    this.user = user;
    this.set('user', user);
  }

  setHomeUserTasks(userTasks) {
    this.homeUserTasks = userTasks;
    this.set('homeUserTasks', this.userTasks);
  }

  setHomeFarmerTasks(farmerTasks) {
    this.homeFarmerTasks = farmerTasks;
    this.set('homeFarmerTasks', this.userTasks);
  }

  setHomeGroups(groups) {
    this.homeGroups = groups;
    this.set('homeGroups', this.homeGroups);
  }
}
